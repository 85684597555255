<template>
  <div>
    <template>
      <div>
        <v-breadcrumbs
          :items="breadcrumb"
          class="px-0 pt-0"
        >
        </v-breadcrumbs>
      </div>
    </template>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <h3 class="my-8">
      Tambah Materi
    </h3>
    <v-card class="pa-4">
      <v-card-text>
        <div class="mb-4">
          <h3>Tambah Arsip Materi (Wajib diisi)</h3>
          <span>Halaman untuk menambahkan Arsip Materi</span>
        </div>
        <div>
          <v-text-field
            v-model="archiveLesson.title"
            label="Judul Materi"
            :rules="[validation.required]"
            outlined
            dense
          ></v-text-field>
        </div>
        <div class="mb-4">
          <h3>Icon</h3>
          <span>Icon untuk materi pelajaran</span>
        </div>
        <div class="d-flex mb-4">
          <v-card
            width="120"
            height="110"
            class="d-flex align-center justify-center"
            style="border: 1px dashed #959595;"
            @click="addIcon"
          >
            <div class="text-center">
              <v-icon
                v-if="!archiveLesson.icon"
                large
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <v-img
                v-else
                v-model="archiveLesson.icon_uuid"
                :rules="[validation.required]"
                width="40"
                :src="archiveLesson.icon"
                class="mx-auto"
              ></v-img>
            </div>
          </v-card>

          <div class="ml-4">
            <h4 class="font-weight-light">
              Icon akan ditampilkan di halaman materi pelajaran
            </h4>
            <h4 class="primary--text mb-10">
              Pilih Icon
            </h4>
            <small>*Icon akan dipilih secara default jika anda ingin melewatkannya</small>
          </div>
        </div>
        <div class="mb-4">
          <h3>Isi materi (Wajib diisi)</h3>
          <p>Materi bisa berupa teks atau video</p>
        </div>

        <div>
          <v-text-field
            v-model="archiveLesson.url_video"
            label="Link Video Materi"
            outlined
            :rules="[validation.required]"
            dense
          >
          </v-text-field>
        </div>
        <div class="mb-5">
          <CKEditor
            v-model="archiveLesson.content"
            label="Content"
            outlined
            :rules="[validation.required]"
            dense
            @input="handleCKeditor"
          ></CKEditor>
        </div>

        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="primary"
          large
          :disabled="!formValidLesson"
          :loading="isLoadingButton"
          @click="add()"
        >
          Simpan
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          @click="cancelAction"
        >
          Batal
        </v-btn>
      </v-card-actions>
    </v-card>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :hidden-actions="false"
      header="Pilih Icon"
      @close="modalDialog = false"
      @close-modal="handlerButton"
    >
      <template v-slot:body>
        <h4>Icon akan ditampilkan di halaman materi pelajaran</h4>
        <p>Pilih salah satu icon:</p>
        <v-row>
          <v-col
            v-for="bankIcon in bankIcons"
            :key="bankIcon.icon"
            cols="3"
          >
            <v-card
              outlined
              height="80"
              class="pa-4 elevation-0 d-flex justify-center align-center"
              clickable
              :color="cardActive == bankIcon.uuid ? 'primary border-none' : ''"
              @click="getIcon(bankIcon.icon, bankIcon.uuid)"
            >
              <div
                class=""
              >
                <v-img
                  width="40"
                  :src="bankIcon.icon"
                  class="mx-auto"
                ></v-img>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          dense
          large
          @click="chooseIcon(icon.icon, icon.uuid)"
        >
          Pilih
        </v-btn>
        <v-btn
          color="primary"
          dense
          large
          outlined
          @click="handlerButton"
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import CKEditor from '@/views/components/CkEditor.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import { integerValidator, required } from '@core/utils/validation'
import { mdiArrowLeft, mdiPlus } from '@mdi/js'

export default {
  name: 'Lesson',
  components: {
    ModalDialog,
    CKEditor,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiArrowLeft,
      },
      archiveLesson: {
        title: '',
        content: '',
        url_video: '',
        icon_uuid: null,
        teacher_uuid: '',
      },
      breadcrumb: [
        {
          text: 'Arsip Materi',
          disabled: false,
          href: '/archive-teacher',
        },
        {
          text: 'Tambah Arsip Materi',
          disabled: true,
        },
      ],
      validation: {
        required,
        integerValidator,
      },
      subject: {},
      chapters: [],
      icon: {
        uuid: '',
        icon: '',
      },
      isLoadingButton: false,
      isDisabledButton: false,
      modalDialog: false,
      width: 500,
      status: true,
      service: 'archivelesson',
      bankIcons: [],
      cardActive: '',
      tempContent: '',
      tempImage: [],
      formValidLesson: false,
      modalDialogIcon: false,
      teacher_uuid: '',
    }
  },
  watch: {
    archiveLesson: {
      handler() {
        const valid = []
        const requiredField = ['title', 'content', 'url_video', 'icon_uuid']
        Object.entries(this.archiveLesson).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValidLesson = !valid.includes(false)
      },
      deep: true,
    },
  },
  mounted() {
    this.getTeacher()
    this.listBankIcon()
  },
  methods: {
    getTeacher() {
      this.teacher = JSON.parse(localStorage.getItem('user')).user.details
      this.teacher.forEach(el => {
        if (el.teacher.uuid === undefined) {
          this.teacher_uuid = el.teacher.uuid

          return
        }
        if (el.teacher.uuid === null) {
          this.showSnackbar({
            text: "You don't have access to teacher",
            color: 'error',
          })

          return
        }
        this.teacher_uuid = el.teacher.uuid
      })

      return true
    },
    cancelAction() {
      this.modalDialog = false
      const image = this.tempContent.split('"')
      image.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const fileName = {
            file: img,
          }
          await this.deleteStorage(fileName)
        }
      })
      this.tempContent = ''

      return this.$router.back()
    },
    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('lesson', fileName).then(({ data }) => true)
    },
    handleCKeditor(event) {
      this.archiveLesson.content = event
      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },
    addIcon() {
      this.modalDialog = true
    },
    chooseIcon(icon, uuid) {
      this.archiveLesson.icon = icon
      this.archiveLesson.icon_uuid = uuid
      this.modalDialog = false
    },
    getIcon(icon, uuid) {
      this.icon.icon = icon
      this.icon.uuid = uuid
      this.cardActive = uuid
    },
    async listBankIcon() {
      await this.$services.ApiServices.list('bankicon').then(
        ({ data }) => {
          this.bankIcons = data.data
        },
        err => {
          console.error(err)
        },
      )
    },
    resetForm() {
      this.archiveLesson.title = ''
      this.icon = null
      this.archiveLesson.icon_uuid = ''
      this.archiveLesson.url_video = ''
      this.archiveLesson.content = ''
    },
    returnBack() {
      return this.$router.back()
    },
    async add() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      const tempImage = this.tempContent.split('"')
      tempImage.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          this.tempImage.push(img)
        }
      })
      const image = this.archiveLesson.content.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)
          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      this.archiveLesson.teacher_uuid = this.teacher_uuid
      await this.$services.ApiServices.add(this.service, this.archiveLesson).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          this.isLoadingButton = false
          this.isDisabledButton = false
          console.error(err)
        },
      )
      if (
        // eslint-disable-next-line operator-linebreak
        this.archiveLesson.title === '' ||
        // eslint-disable-next-line operator-linebreak
        this.archiveLesson.content === '' ||
        this.archiveLesson.icon_uuid === null
      ) {
        this.showSnackbar({
          text: 'field tidak boleh ada yang kosong!!',
          color: 'error',
        })
      }
      this.resetForm()
      this.returnBack()
      this.isLoadingButton = false
      this.isDisabledButton = false
    },
    handlerButton() {
      this.archiveLesson.icon = null
      this.archiveLesson.icon_uuid = null
      this.modalDialog = false
      this.cardActive = ''
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 15px !important;
}
.clickable {
  cursor: pointer;
}
.border-none {
  border: none;
}
</style>
